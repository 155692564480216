import React, { Component } from 'react'
import Layout from '../components/layout'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Home from '../components/home/component'

export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      brands: [],
    }
  }

  searchTerm(val) {
    this.setState({ searchTerm: val })
  }

  brands(val) {
    this.setState({ brands: val })
  }

  render() {
    return (
      <Layout {...this.props}>
        <Header
          brands={this.state.brands}
          searchTerm={this.searchTerm.bind(this)}
          {...this.props}
        />
        <Home
          brands={this.brands.bind(this)}
          searchTerm={this.state.searchTerm}
          {...this.props}
        />
        <Footer />
      </Layout>
    )
  }
}
